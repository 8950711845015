import React, { useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import {
    AppBar, CssBaseline, Toolbar, Typography, Popper,
    makeStyles, Container, Menu, MenuItem, IconButton, Grid, useMediaQuery
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import AutoComplete from '../components/AutoComplete'



const Master = (props) => {

    const data = useStaticQuery(graphql`
      {
        allCategory(filter: {isFeatured: {eq: true}}, sort: {order: ASC, fields: title}) {
          nodes {
            id
            title
            slug
          }
        }
        allProduct {
          totalCount
        }
        allService {
            totalCount
        }
        allPost {
        totalCount
        }
        BACKGROUND_COLOR: setting(environmentName: {eq: "BACKGROUND_COLOR"}) {
          value
        }
        AUX_COLOR_COMPLEMENT: setting(environmentName: {eq: "AUX_COLOR_COMPLEMENT"}) {
          value
        }
        FOOTER_COLOR: setting(environmentName: {eq: "FOOTER_COLOR"}) {
          value
        }
        SECONDARY_COLOR: setting(environmentName: {eq: "SECONDARY_COLOR"}) {
          value
        }
        MAIN_BACKGROUND_COLOR_COMPLEMENT: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR_COMPLEMENT"}) {
          value
        }
        MAIN_BACKGROUND_COLOR: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR"}) {
          value
        }
        BUTTON_BACKGROUND_COLOR: setting(environmentName: {eq: "BUTTON_BACKGROUND_COLOR"}) {
            value
          }
        MENU_COLOR: setting(environmentName: {eq: "MENU_COLOR"}) {
          value
        }
        WHATS_APP_NUMBER: setting(environmentName: {eq: "WHATS_APP_NUMBER"}) {
          value
        }
        YOUTUBE_URL: setting(environmentName: {eq: "YOUTUBE_URL"}) {
          value
        }
        FACEBOOK_URL: setting(environmentName: {eq: "FACEBOOK_URL"}) {
          value
        }
        INSTAGRAM_URL: setting(environmentName: {eq: "INSTAGRAM_URL"}) {
          value
        }
        FOOTER_MAP: setting(environmentName: {eq: "FOOTER_MAP"}) {
          value
        }
        FOOTER_CITY: setting(environmentName: {eq: "FOOTER_CITY"}) {
          value
        }
        FOOTER_ADDRESS: setting(environmentName: {eq: "FOOTER_ADDRESS"}) {
          value
        }
      }
    `)

    // const categories = data.allCategory.nodes
    const PRODUCTS_COUNT = data.allProduct.totalCount
    const SERVICES_COUNT = data.allService.totalCount
    // const POSTS_COUNT = data.allPost.totalCount
    const AUX_COLOR_COMPLEMENT = data.AUX_COLOR_COMPLEMENT.value
    const FOOTER_COLOR = data.FOOTER_COLOR.value
    const SECONDARY_COLOR = data.SECONDARY_COLOR.value
    const MAIN_BACKGROUND_COLOR_COMPLEMENT = data.MAIN_BACKGROUND_COLOR_COMPLEMENT.value
    const MAIN_BACKGROUND_COLOR = data.MAIN_BACKGROUND_COLOR.value
    const BUTTON_BACKGROUND_COLOR = data.BUTTON_BACKGROUND_COLOR.value
    const MENU_COLOR = data.MENU_COLOR.value
    const WHATS_APP_NUMBER = data.WHATS_APP_NUMBER.value
    const YOUTUBE_URL = data.YOUTUBE_URL.value
    const FACEBOOK_URL = data.FACEBOOK_URL.value
    const INSTAGRAM_URL = data.INSTAGRAM_URL.value
    const FOOTER_MAP = data.FOOTER_MAP.value
    const FOOTER_CITY = data.FOOTER_CITY.value
    const FOOTER_ADDRESS = data.FOOTER_ADDRESS.value

    const useStyles = makeStyles(theme => ({
        '@global': {
            body: {
                fontFamily: `${process.env.FONT_FAMILY}`,
                fontSize: `${process.env.FONT_SIZE}`,
            },
            h1: {
                fontSize: '3rem',
                color: `${BUTTON_BACKGROUND_COLOR}`
            },
            h2: {
                fontSize: '2.5rem',
                color: `${BUTTON_BACKGROUND_COLOR}`
            },
            h3: {
                fontSize: '2rem',
                color: `${BUTTON_BACKGROUND_COLOR}`
            },
            h4: {
                fontSize: '1.6rem',
                color: `${BUTTON_BACKGROUND_COLOR}`
            },
            ul: {
                margin: 0,
                padding: 0,
                listStyle: 'none',
            },
        },
        appBar: {
            borderBottom: `1px solid ${MAIN_BACKGROUND_COLOR_COMPLEMENT}`,
            backgroundColor: `${MAIN_BACKGROUND_COLOR}`
        },
        toolbar: {
            flexWrap: 'wrap',
        },
        toolbarTitle: {
            flexGrow: 1,
            paddingTop: '6px'
        },
        link: {
            margin: theme.spacing(1, 1.5),
            color: `${MENU_COLOR}`,
            textDecoration: 'none',
            '&:hover': {
                color: `${SECONDARY_COLOR}`
            },
            [theme.breakpoints.down('md')]: {
                color: `${MAIN_BACKGROUND_COLOR}`,
                textDecoration: 'none',
                '&:hover': {
                    color: `${MAIN_BACKGROUND_COLOR_COMPLEMENT}`
                },
            }
        },
        desktop: {
            display: 'block',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        mobile: {
            display: 'block',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            },
        },
        content: {
            padding: theme.spacing(0, 3, 6),
        },
        footer: {
            padding: theme.spacing(3, 2),
            marginTop: 'auto',
            backgroundColor: `${AUX_COLOR_COMPLEMENT}`,
            '& a': {
                textDecoration: 'none',
                color: `${FOOTER_COLOR}`
            }
        },
        logoHeader: {
            textAlign: 'center',
            backgroundImage: 'linear-gradient(#CCC, #FFFFFF)',
            '& img': {
                height: '110px',
                marginTop: '15px',
                marginBottom: '15px'
            }
        },
        centerFooter: {
            textAlign: 'center',
            marginTop: '30px'
        },
        liFooter: {
            marginTop: '10px'
        },
        menuButton: {
            color: `${MENU_COLOR}`
        },
        paper: {
            border: `1px solid ${MAIN_BACKGROUND_COLOR}`,
            width: '300px',
            borderRadius: '5px',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
        space: {
            marginRight: '10px',
            color: `${MENU_COLOR}`
        },
        menu: {
            marginRight: '10px',
            color: `${MENU_COLOR}`
        },
        iconFooter: {
            fontSize: '2.5rem'
        },
        logo: {
            width: '80%',
            height: 'auto !important'
        },
        googleMaps: {
            position: 'relative',
            paddingBottom: '75%',
            height: '0',
            overflow: 'hidden',
            iframe: {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100% !important',
                height: '100% !important'
            }
        }
    }));

    const classes = useStyles();

    const logoMediaQuery = useMediaQuery('(min-width:380px)');

    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElPopper, setAnchorElPopper] = useState(null)

    const handleClickPopper = (service) => {
        setAnchorElPopper(anchorElPopper ? null : service.currentTarget);
    }

    const handleClickWhatsApp = () => {
        window.open(`https://api.whatsapp.com/send?phone=55${WHATS_APP_NUMBER}`, '_blank');
    }

    const open = Boolean(anchorElPopper)
    const id = open ? 'simple-popper' : undefined

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <CssBaseline />
            <div className={classes.logoHeader}>
                <Link to={`/`}>
                    {logoMediaQuery ?
                        <img src='/indall-logo.png' title={props.title || process.env.SITE_NAME} alt={props.title} />
                        : <img className={classes.logo} src='/indall-logo.png' title={props.title || process.env.SITE_NAME} alt={props.title} />
                    }
                </Link>
            </div>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        <Link to="/" className={classes.link}>
                            Início
                        </Link>
                    </Typography>
                    <nav>
                        <div className={classes.desktop}>
                            {/* {categories.map(category => (
                                <Link key={category.id} variant="button" color="textPrimary" to={`/categorias/${category.slug}`} className={classes.link}>
                                    {category.title}
                                </Link>
                            ))} */}
                            <Link variant="button" color="textPrimary" to="/quemsomos" className={classes.link} rel="nofollow">
                                Quem somos
                            </Link>
                            {PRODUCTS_COUNT > 0 ?
                                (<Link variant="button" color="textPrimary" to="/produtos" className={classes.link}>
                                    Produtos
                                </Link>) : null}
                            {SERVICES_COUNT > 0 ?
                                (<Link variant="button" color="textPrimary" to="/servicos" className={classes.link}>
                                    Serviços
                                </Link>) : null}
                            {/* {POSTS_COUNT > 0 ?
                                (<Link variant="button" color="textPrimary" to="/informativos" className={classes.link}>
                                    Informativos
                                </Link>) : null} */}
                            <Link variant="button" color="textPrimary" to="/qualidade" className={classes.link} rel="nofollow">
                                Qualidade
                            </Link>                            
                            {/* <Link variant="button" color="textPrimary" to="/trabalheconosco" className={classes.link} rel="nofollow">
                                Trabalhe Conosco
                            </Link> */}
                            <Link variant="button" color="textPrimary" to="/contato" className={classes.link} rel="nofollow">
                                Contato
                            </Link>
                            <IconButton className={classes.menuButton} color="inherit" aria-label="menu" aria-describedby={id} onClick={handleClickPopper}>
                                <SearchIcon />
                            </IconButton>
                            <IconButton edge="start" className={classes.menuButton} aria-label="menu" onClick={handleClickWhatsApp}>
                                <WhatsAppIcon />
                            </IconButton>
                        </div>
                        <div className={classes.mobile}>
                            <SearchIcon aria-describedby={id} onClick={handleClickPopper} className={classes.space} />
                            <MenuIcon onClick={handleClick} className={classes.menu} />
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {/* {categories.map(category => (
                                    <MenuItem key={category.id} >
                                        <Link variant="button" color="textPrimary" to={`/categorias/${category.slug}`} className={classes.link}>
                                            {category.title}
                                        </Link>
                                    </MenuItem>
                                ))} */}
                                <MenuItem>
                                    <Link variant="button" color="textPrimary" to="/" className={classes.link}>
                                        Início
                                        </Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link variant="button" color="textPrimary" to="/quemsomos" className={classes.link} rel="nofollow">
                                        Quem somos
                                    </Link>
                                </MenuItem>
                                {PRODUCTS_COUNT > 0 ?
                                    (<MenuItem>
                                        <Link variant="button" color="textPrimary" to="/produtos" className={classes.link}>
                                            Produtos
                                        </Link>
                                    </MenuItem>) : null}
                                {SERVICES_COUNT > 0 ?
                                    (<MenuItem>
                                        <Link variant="button" color="textPrimary" to="/servicos" className={classes.link}>
                                            Serviços
                                        </Link>
                                    </MenuItem>) : null}
                                {/* {POSTS_COUNT > 0 ?
                                    (<MenuItem>
                                        <Link variant="button" color="textPrimary" to="/informativos" className={classes.link}>
                                            Informativos
                                        </Link>
                                    </MenuItem>) : null} */}
                                <MenuItem>
                                    <Link variant="button" color="textPrimary" to="/qualidade" className={classes.link} rel="nofollow">
                                        Qualidade
                                    </Link>
                                </MenuItem>                                
                                {/* <MenuItem>
                                    <Link variant="button" color="textPrimary" to="/trabalheconsoco" className={classes.link} rel="nofollow">
                                        Trabalhe Conosco
                                    </Link>
                                </MenuItem> */}
                                <MenuItem>
                                    <Link variant="button" color="textPrimary" to="/contato" className={classes.link}>
                                        Contato
                                    </Link>
                                </MenuItem>
                                <MenuItem>
                                    {/* <Link variant="button" color="textPrimary" to={`https://api.whatsapp.com/send?phone=55${WHATS_APP_NUMBER}`} className={classes.link} rel="nofollow">
                                        <WhatsAppIcon />
                                    </Link> */}
                                    <a aria-label="WhatsApp" href={`https://api.whatsapp.com/send?phone=55${WHATS_APP_NUMBER}`} target="_blank" rel="noopener noreferrer" className={classes.link}><WhatsAppIcon /></a>
                                </MenuItem>
                            </Menu>
                        </div>
                    </nav>
                </Toolbar>
            </AppBar>
            {/* <Container maxWidth="md" component="main" className={classes.content}> */}
            {props.children}
            {/* </Container> */}
            <Popper id={id} open={open} anchorEl={anchorElPopper}>
                <div className={classes.paper}><AutoComplete /></div>
            </Popper>
            <footer className={classes.footer}>
                <Container maxWidth="md">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <h4>{process.env.SITE_NAME}</h4>
                            <ul>
                                {/* {categories.map(category => (
                                    <li key={category.id} className={classes.liFooter}>
                                        <Link to={`/categorias/${category.slug}`}>
                                            {category.title}
                                        </Link>
                                    </li>
                                ))} */}
                                <li className={classes.liFooter}>
                                    <Link to="/quemsomos" rel="nofollow">
                                        Quem Somos
                                    </Link>
                                </li>
                                {PRODUCTS_COUNT > 0 ?
                                    (<li className={classes.liFooter}>
                                        <Link to="/produtos">
                                            Produtos
                                    </Link>
                                    </li>) : null}
                                {SERVICES_COUNT > 0 ?
                                    (<li className={classes.liFooter}>
                                        <Link to="/servicos">
                                            Serviços
                                    </Link>
                                    </li>) : null}
                                {/* {POSTS_COUNT > 0 ?
                                    (<li className={classes.liFooter}>
                                        <Link to="/informativos">
                                            Informativos
                                    </Link>
                                    </li>) : null} */}
                                <li className={classes.liFooter}>
                                    <Link to="/qualidade" rel="nofollow">
                                        Qualidade
                                    </Link>
                                </li>                                
                                {/* <li className={classes.liFooter}>
                                    <Link to="/trabalhaconosco" rel="nofollow">
                                        Trabalhe Conosco
                                    </Link>
                                </li> */}
                            </ul>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <h4>Fale com a gente!</h4>
                            {YOUTUBE_URL ?
                                (<a href={`${YOUTUBE_URL}`} target="_blank" rel="nofollow noreferrer">
                                    <YouTubeIcon className={classes.iconFooter} />
                                </a>) : null}
                            {FACEBOOK_URL ?
                                (<a href={`${FACEBOOK_URL}`} target="_blank" rel="nofollow noreferrer">
                                    <FacebookIcon className={classes.iconFooter} />
                                </a>) : null}
                            {INSTAGRAM_URL ?
                                (<a href={`${INSTAGRAM_URL}`} target="_blank" rel="nofollow noreferrer">
                                    <InstagramIcon className={classes.iconFooter} />
                                </a>) : null}
                            <a href={`https://api.whatsapp.com/send?phone=55${WHATS_APP_NUMBER}`} target="_blank" rel="nofollow noreferrer">
                                <WhatsAppIcon className={classes.iconFooter} />
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <h4>Localização</h4>
                            <p>
                                {FOOTER_ADDRESS}<br />{FOOTER_CITY}</p>
                            <div className={classes.googleMaps}>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30019.337398962354!2d-42.98605759032091!3d-19.864598844970093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa51a4bd62a16a7%3A0x1ba11c1e287c95a4!2sS%C3%A3o%20Domingos%20do%20Prata%2C%20MG%2C%2035995-000!5e0!3m2!1spt-BR!2sbr!4v1593640995514!5m2!1spt-BR!2sbr" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                                <div dangerouslySetInnerHTML={{ __html: FOOTER_MAP }} />
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.centerFooter}>
                        <p>Copyright &copy; {new Date().getFullYear() === 2020 ? `${new Date().getFullYear()}` : `2020 / ${new Date().getFullYear()}`} - {process.env.SITE_NAME}. Todos os direitos reservados. Desenvolvido por <a href="https://www.clicksolucoesweb.com.br" target="_blank" rel="nofollow noreferrer">Click Soluções Web</a></p>
                    </div>
                </Container>
            </footer>
        </>
    )
}

export default Master